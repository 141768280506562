@import 'variables';
@import 'mixin';

// Variables
$border-color: #ddd;
$background-color: #fff;
$hover-color: #f7f7f7;
$text-color: #121212;
$text-secondary: #666;
$icon-size: 20px;
$gap: 10px;

// Mixins
@mixin flex-between {
  display: flex;
  justify-content: space-between;
}

@mixin transition($property: all, $duration: 0.3s, $timing: ease) {
  transition: $property $duration $timing;
}

// Styles
.transaction-container {
  padding-top: 2rem;
  margin: 0 auto;
}

.transaction-tab {
  padding: 10px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.10);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
  @include transition(all, 0.3s, ease-in-out);


  .transaction-summary {
    padding: 10px;
    cursor: pointer;
  }

  // New wrapper classes
  .transaction-information-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .content-left {
    @include content-left;

    .merchant-name {
      display: block;
      font-weight: 600;
      font-size: 18px;
      color: $text-color;
      margin-bottom: 5px;
    }

    .date-time-wrapper {
      display: flex;
      font-weight: 500;
      font-size: 0.9em;
      color: $text-secondary;

      .transaction-date,
      .transaction-time {
        display: inline-block;
      }

      .transaction-time {
        margin-left: 10px;
      }
    }
  }

  .content-right {
    @include content-right;

    .transaction-amount {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 5px;
      color: $text-color;
    }

    .transaction-navigation-wrapper {
      display: flex;
      align-items: flex-end;
      gap: 0.5rem;

      button {
        background: none;
        border: none;
        cursor: pointer;
        border-radius: 50%;
        @include transition;

        &:hover {
          background-color: $hover-color;
        }

        .icon {
          width: $icon-size;
          height: $icon-size;
          color: $text-secondary;
          @include transition;
        }

        &.open-button .icon.rotated {
          transform: rotate(90deg);
        }
      }
    }
  }

  &.expanded {
    .transaction-summary {
    }

    .date-time-wrapper {
      color: $primary-black;
    }
  }
}

// Keep existing styles for transaction-detail and media queries
.transaction-detail {
  background-color: white;
  box-sizing: border-box;
  position: relative;
  padding: 10px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .transaction-header {
    @include flex-between;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .transaction-date {
    color: $text-secondary;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .transaction-detail {
    padding: 0rem;
  }

  .transaction-tab {
    .transaction-summary {
      padding: 0rem;
    }


    .transaction-information-wrapper {
      .merchant-name {
        font-size: 16px;
      }
      .transaction-amount {
        font-size: 16px;
      }
    }

    .transaction-navigation-wrapper {
      gap: 0.25rem;
    }
  }
}

// Animation
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.transaction-detail {
  animation: fadeIn 0.3s ease-in-out;
}

.connection-status {
  padding: 8px 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  font-size: 14px;

  &.connecting {
    background-color: #fff3cd;
    color: #856404;
  }

  &.connected {
    background-color: #d4edda;
    color: #155724;
  }

  &.disconnected {
    background-color: #f8d7da;
    color: #721c24;
  }
}

.no-transactions {
  text-align: center;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 8px;
  color: #6c757d;
  font-size: 16px;
}