@import 'variables';

$gap:10px;
$max-width:1000px;

@mixin inner-container {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 20px;
}

@mixin content-left {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  gap: $gap;
}

@mixin content-right {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1 0 0;
  gap: $gap;
}

// Mixins
@mixin flex-layout($direction: row, $justify: flex-start, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin text-style($size, $weight: 400) {
  font-family: 'Poppins', sans-serif;
  font-size: $size;
  font-weight: $weight;
  line-height: 1.5;
  color: $color-primary;
}

@mixin poppins-small {
  font-size: $font-size-small;
  font-family: 'poppins', sans-serif;
}

@mixin gabarito {
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
}