@import 'variables';

.vat-return-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 23px;
  width: 100%;

  // Return Information Section
  .return-information-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    flex: 1;
    border-radius: 5px;

    .return-header {
      display: block;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 5px;
    }

    .return-barcode-qrcode-wrapper {
      display: flex;
      width: 100%;
    }
  }

  // VAT Information Section
  .vat-information-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    flex: 1;
    background: #FFF9EF;
    border-radius: 5px;

    h6 {
      display: block;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .item-info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 5px 0;
      gap: 5px;
      width: 100%;
    }

    // Common row styles
    .vat-percentage-amount-wrapper,
    .amount-exclvat-wrapper,
    .amount-inclvat-wrapper {
      display: flex;
      width: 100%;
      gap: 5px;

      .left-wrapper,
      .right-wrapper {
        display: flex;
        align-items: center;
        flex: 1;

        span {
          font-size: 13px;
        }
      }

      .right-wrapper {
        justify-content: flex-end;
      }
    }

    .vat-sumtotal-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 5px;
      padding-top: 5px;

      .separator {
        width: 100%;
        height: 0;
        border: 1px solid $medium-yellow;
      }

      .amount-inclvat-wrapper {
        span {
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .vat-return-container {
    flex-direction: column;
    height: auto;
    gap: 16px;

    .return-information-wrapper,
    .vat-information-container {
      max-width: none;
      width: 100%;
    }

    .return-barcode-qrcode-wrapper {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        display: flex !important;
        align-items: center !important;
      }
    }
  }
}