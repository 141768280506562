// src/styles/layout.scss
@import 'variables';
@import 'mixin';


// Header Styles
.header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 10px 10px;
  background-color: $primary-black !important;

  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: $primary-white;
  }

  .inner-container {
    @include inner-container;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border: none;

    .logo {
      img {
        height: 40px;
        width: auto;
      }
    }

    .nav-menu {
      ul {
        display: flex;
        list-style: none;
        gap: 2rem;
        margin: 0;
        padding: 0;

        li {
          position: relative;

          .nav-link {
            display: inline-block;
            padding: 0.5rem 1rem;
            font-weight: 500;
            color: $primary-white;
            text-decoration: none;  // Remove default underline
            position: relative;
            cursor: pointer;

            // Underline effect container
            &::after {
              content: '';
              position: absolute;
              bottom: -2px;  // Slightly lower than text
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $primary-white;
              transform: scaleX(0);  // Hide initially
              transform-origin: center;
              transition: transform 0.3s ease;
            }

            // Show underline on hover
            &:hover::after {
              transform: scaleX(1);
            }
          }

          // Active state
          &.active .nav-link::after {
            transform: scaleX(1);  // Show underline for active state
          }
        }
      }
    }
  }
}

// Optional: Add responsive styles
@media (max-width: 768px) {
  .header {
    .inner-container {
      .nav-menu {
        ul {
          gap: 1rem;  // Reduce gap on mobile
        }
      }
    }
  }
}

// Main Content Styles
.content-container {
  @include inner-container;
  padding-top: 40px;
  padding-bottom: 40px;
}


// Footer Styles
.footer {
  background-color: $primary-black;
  color: $primary-white;
  padding: 20px 0;
  margin-top: 40px;

  .inner-container {
    @include inner-container;
    text-align: center;
    border: none;
  }
}
