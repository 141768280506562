.ID-Information-Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  .header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .Timer {
      font-weight: 500;
      color: #1C1C1C;
      padding: 4px 8px;
      background: #FFEDCF;
      border-radius: 4px;
    }
  }

  .id-container {
    width: 100%;
    height: 100%;
    padding: 0 0 30px 0;
  }

  .profile-container {
    display: flex;
    gap: 40px;
    width: 100%;
    padding: 10px 0;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .profile-image {
      width: 300px;
      height: auto;
      border-radius: 5px;
      overflow: hidden;

      @media (max-width: 768px) {
        margin-top: 20px;
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }


    .Profile-Details {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px 0;

      .Detail-Row {
        display: flex;
        gap: 10px;
        align-items: center;

        .Label {
          font-weight: 600;
        }

        .Value {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #1C1C1C;
        }
      }
    }
  }
}

  .logo-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .sto-logo {
      width: 60px !important;
      height: auto !important;
    }
  }
