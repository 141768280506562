.bonus-points {
    margin-top: 1rem;
    border: 1px dashed $primary-border-color;
    border-radius: 6px;
    padding: 0.8rem;
  
    h6 {
      margin-bottom: 0.5rem;
    }
  
    .bonus-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .label {
        font-weight: 600;
        color: $secondary-black;
      }
  
      .value {
        background-color: lighten($primary-green, 30%);
        padding: 0.3rem 0.6rem;
        border-radius: 4px;
        font-weight: 600;
        color: $secondary-black;
      }
    }
  }