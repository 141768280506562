.Information-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px !important;
  gap: 10px;
  width: 100%;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  flex: none;
  order: 0;
  flex-grow: 1;

  .Header-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;

    .RewardIcon {
      width: 24px;
      height: 24px;
      flex: none;
      order: 0;
      flex-grow: 0;

      svg {
        width: 83.33%;
        height: 75.48%;
        stroke: #000000;
        stroke-width: 1.5px;
      }
    }

    .reward-title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      align-items: center;
      color: #1C1C1C;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }

  .Left-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    .Item-Info-Wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 5px 0px;
      gap: 5px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }

    .Program-Row-Wrapper,
    .Member-Number-Wrapper,
    .Intermediate-Wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 5px;
      flex: none;
      align-self: stretch;
      flex-grow: 0;

      .Left-Wrapper,
      .Right-Wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;
        flex: none;
        flex-grow: 1;

        span {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          display: flex;
          align-items: center;
          color: #1C1C1C;
        }
      }

      .Right-Wrapper {
        justify-content: flex-end;
      }
    }

    .Points-Wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 5px 0px 0px;
      gap: 5px;
      flex: none;
      order: 2;
      align-self: stretch;
      flex-grow: 0;

      .Separator {
        height: 0px;
        border: 1px solid #FFEDCF;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }

      .Intermediate-Wrapper {
        .Left-Wrapper,
        .Right-Wrapper {
          span {
            font-weight: 500;
          }
        }
      }
    }
  }
}