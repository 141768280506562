// Global styles

@import 'variables';
@import 'layout';
@import 'transactions';
@import 'lineItems';
@import 'paymentMethod';
@import 'vatReturn';
@import 'merchantInfo';
@import 'receiptHeader';
@import 'warranty';
@import 'rewardProgram';
@import 'bonusPoints';
@import 'returnInfoCard';
@import 'receiptActions';
@import 'typography';
@import 'mixin';
@import 'idPage';


// PrimeReact core CSS
@import 'primereact/resources/primereact.min.css';

// PrimeIcons
@import 'primeicons/primeicons.css';



// Reset styles
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// Base container styles
.container {
  margin: $container-margin 0;
  background-color: $background-color;
  border-radius: $border-radius;

  &-heading {
    font-size: 1.5rem;
    color: $primary-black;
    margin-bottom: 1rem;
    font-weight: 600;
  }
}

.inner-container {
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: $border-radius;

  @media (max-width: 768px) {
    padding: 5px;
  }

  &-heading {
    font-size: 1.25rem;
    color: $primary-black;
    margin-bottom: 1rem;
    font-weight: 500;
  }
}

.wrapper {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
}

.left-container,
.center-container,
.right-container {
}


// Typography
body {
  font-family: $font-family-poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.dashed-separator {
  border-top: 0.5px dashed $primary-border-color;
  margin: 0.5rem 0 1rem;
  width: 100%;
}

.content-container {
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin: 0 0 1rem 0;
}

// Paragraph sizes

.p-xs {
  font-size: 0.8rem; // 12px if base is 16px
}

.p-sm {
  font-size: 0.875rem; // 14px if base is 16px
}

.p-md {
  font-size: 1rem; // 16px
}

.p-lg {
  font-size: 1.125rem; // 18px if base is 16px
}

// Links
a {
  color: $primary-black;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
}

// Lists
ul, ol {
  margin: 0 0 1rem 0;
}

// Utility classes
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.font-light {
  font-weight: 300;
}

// Margins and paddings
.mt-1 { margin-top: 0.25rem; }
.mt-2 { margin-top: 0.5rem; }
.mt-3 { margin-top: 1rem; }
.mt-4 { margin-top: 1.5rem; }
.mt-5 { margin-top: 2rem; }

.mb-1 { margin-bottom: 0.25rem; }
.mb-2 { margin-bottom: 0.5rem; }
.mb-3 { margin-bottom: 1rem; }
.mb-4 { margin-bottom: 1.5rem; }
.mb-5 { margin-bottom: 2rem; }

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}