.return-info-card {
    overflow: hidden;
    margin-top: 4rem;

  
    .return-info-content {
      border: 1px dashed $primary-border-color;
      border-radius: 8px;
  
      .barcode-container {
        display: flex;
        justify-content: center;
        align-items: center;
  
        .ean-barcode-wrapper,
        .qr-code-wrapper {
          background-color: white;
          padding: 1rem;
          border-radius: 4px;
        }
  
        .no-code-available {
          color: $secondary-grey;
          font-style: italic;
        }
      }
  
      .return-info-details {
        .return-info-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem;
  
          &:last-child {
            margin-bottom: 0;
          }
  
          .label {
            font-weight: 600;
            color: $secondary-black;
          }
  
          .value {
            color: $primary-black;
          }
        }
      }
    }
  
    .return-info-footer {
      border-radius: 8px;
      background-color: $primary-container-grey;
      padding: 1rem;
      text-align: center;
  
      p {
        margin: 0;
        color: $secondary-black;
        font-size: 0.9rem;
      }
    }
  }