// Colors
$primary-button-color: #C6E1DB;
$secondary-button-color: #f1f3f5;

$primary-black: #323232;
$secondary-black: #515151;

$primary-green: #A5E1D3;
$secondary-green: #C6E1DB;

$light-yellow: #FFF9EF;
$medium-yellow: #FFEDCF;

$primary-yellow: #F9B633;
$secondary-yellow: #FACC71;

$primary-white: #fff;

$primary-red: #EFDDE0;

$primary-grey: #f5f5f5;
$secondary-grey: #525252;

$primary-border-color: #e0e0e0;
$primary-container-grey: #f7f7f7;
$secondary-container-grey: #dbdbdb;


$shadow-card: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
$color-primary: #1C1C1C;
$color-background: #FFFFFF;
$color-accent: #FFEDCF;


$gap: 10px;

$font-size: 16px;
$font-size-small: 14px;

$container-padding: 1.5rem;
$container-margin: 1rem;
$border-radius: 0.5rem;
$primary-black: #1C1C1C;
$secondary-color: #666;
$background-color: #fff;
$border-color: #e5e7eb;

$inner-container-width:1000px;

