$font-family-poppins: 'Poppins', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-base: $font-family-poppins;

$fw-100: 100;
$fw-300: 300;
$fw-400: 400;
$fw-regular: 500;
$fw-600: 600;
$fw-700: 700;

$font-size-1: 16px;
$font-size-1-media: 14px; // Breaking point 750px

$font-size-2: 13px;

$font-size-3: 12px;



$line-height-base: 1.5;


body {
  font-family: $font-family-base;
  font-size: $font-size-1;
  line-height: $line-height-base;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-poppins;
}

.roboto {
  font-family: $font-family-roboto;
}

.poppins-heading {
  font-family: $font-family-poppins;
  font-size: $font-size-1;
  font-weight: $fw-regular;
  padding: 0 0 5px 0;

  @media (max-width: 750px) {
    font-size: $font-size-1-media;
  }
}

.poppins {
  font-family: $font-family-poppins;
  font-size: $font-size-2;
  font-weight: $fw-400;
}

.poppins-small {
  font-family: $font-family-poppins;
  font-size: $font-size-3;
  font-weight: $fw-regular;
}

