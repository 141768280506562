.Reward-Information-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px !important;
  gap: 10px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  flex: none;
  order: 1;
  flex-grow: 1;

  .Header-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;

    .WarrantyIcon {
      width: 24px;
      height: 24px;
      flex: none;
      order: 0;
      flex-grow: 0;

      svg {
        width: 70.83%;
        height: 83.34%;
        stroke: #000000;
        stroke-width: 1.5px;
      }
    }

    span {
      width: 89px;
      height: 24px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #1C1C1C;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }

  .Left-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    .Item-Info-Wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 5px 0px;
      gap: 5px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }

    .Duration-Wrapper,
    .Expires-On-Wrapper,
    .Status-Wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 5px;
      flex: none;
      align-self: stretch;
      flex-grow: 0;

      .Left-Wrapper,
      .Right-Wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;
        flex: none;
        flex-grow: 1;

        span {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #1C1C1C;
        }
      }

      .Right-Wrapper {
        justify-content: flex-end;
      }
    }

    .Warranty-Status-Wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 5px 0px 0px;
      gap: 5px;
      flex: none;
      order: 2;
      align-self: stretch;
      flex-grow: 0;

      .Separator {
        border: 1px solid #FFEDCF;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }

      .Status-Wrapper {
        .Left-Wrapper,
        .Right-Wrapper {
          span {
            font-weight: 500;
          }
        }

        .Right-Wrapper {
          .Ellipse {
            width: 14px;
            height: 14px;
            background: #CF6F6F;
            border-radius: 50%;
            flex: none;
            order: 1;
            flex-grow: 0;
          }
        }
      }
    }
  }
}