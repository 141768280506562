@import './_variables.scss';
@import './typography.scss';

.payment-method-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  .payment-method-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 10px;

    .payment-table {
      width: 100%;
      border-spacing: 0;
      border-collapse: separate;
      padding: 10px 0 0 0;

      .table-row {
        width: 100%;

        .left-content {
          padding: 5px 10px;
          vertical-align: middle;
          width: auto;
          min-width: 150px;

          .info-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            .dot {
              width: 6px;
              height: 6px;
              background: #FFEDCF;
              border-radius: 50%;
              flex-shrink: 0;
            }

            .label-text {
              font-size: 13px;
              font-weight: 500;
              white-space: nowrap;
            }
          }
        }

        .right-content {
          padding: 5px 10px;
          vertical-align: middle;
          width: 100%;
          display: flex;
          justify-content: flex-end;

          .value-text {
            font-size: 13px;
          }
        }
      }
    }

    .logo-wrapper {
      display: flex;
      padding: 1rem 0 0 0;
      justify-content: flex-end;
      align-items: flex-end;
      margin: 10px 20px 10px 0;

      .card-logo {
        width: 50px;
        height: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .payment-method-container {
    .payment-method-wrapper {
      padding: 10px;

      .payment-table {
        .table-row {
          .left-content,
          .right-content {
            display: block;
            width: 100% !important;
            padding: 5px;
          }

          .right-content {
            justify-content: flex-start;
          }
        }
      }

      .logo-wrapper {
        height: 15px;

        .card-logo {
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}