@import './_variables.scss';
@import './typography.scss';

.line-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 11px;
  width: 100%;
  height: auto;

  .line-items-table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 10px;
    width: 100%;
    height: 44px;

    .frame-7 {
      flex: 2;

      .poppins-heading {
        justify-content: flex-start;
      }
    }

    .frame-8 {
      flex: 1;

      .poppins-heading {
        justify-content: center;
      }
    }

    .frame-9 {
      flex: 1;

      .poppins-heading {
        justify-content: flex-end;
      }
    }

    .frame-7, .frame-8, .frame-9 {
      display: flex;

      .poppins-heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 100%;
      }
    }
  }

  .line-item-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 10px;
    gap: 5px;

    .content-row {
      display: flex;
      width: 100%;
      min-height: min-content;
      gap: 10px;
      align-items: stretch;

      // Common styles for all content containers
      .left-content,
      .center-content,
      .right-content {
        display: flex;
        align-items: flex-start;
        padding: 8px;
        min-height: 100%;
      }

      .left-content {
        flex: 2;
        gap: 10px;

        .item-title {
          font-weight: 600;
          color: #1C1C1C;
          word-break: break-word;
        }

        .item-id {
          background: #FFEDCF;
          padding: 1px 5px;
          border-radius: 10px;
          font-size: 12px;
          white-space: nowrap;
        }

        .dot {
          width: 6px;
          height: 6px;
          background: #FFEDCF;
          border-radius: 50%;
          flex-shrink: 0;
          margin-top: 8px;
        }
      }

      .center-content {
        flex: 1;
        justify-content: center;

      }

      .right-content {
        flex: 1;
        justify-content: flex-end;

      }
    }
  }

  @media (max-width: 768px) {
    .line-item-wrapper {
      .content-row {
        padding: 5px;

        .left-content,
        .center-content,
        .right-content {
          padding: 5px;
        }

        .item-id {
          display: none !important;
        }
      }
    }
  }
}