@import 'mixin';

.merchant-information-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 0;
  gap: 40px;
  width: 100%;
  height: auto;

  .merchant-information-left {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .merchant-information-header-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      gap: 5px;

      .heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0;
        gap: 10px;

        .merchant-title {
          display: flex;
          align-items: center;
          margin: 0;
        }
      }

      .item-id-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 3px 10px;
        gap: 10px;
        background: #FFEDCF;
        border-radius: 10px;
      }
    }

    .merchant-information-buttons-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .merchant-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        gap: 10px;
        min-width: 220px;
        background: #FFF9EF;
        border: none;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
          background: darken(#FFF9EF, 5%);
        }

        .merchant-button-icon {
          width: 16px;
          height: 16px;
        }

        span {
          display: flex;
          align-items: center;
          flex-grow: 1;
        }
      }
    }
  }

  .merchant-map-wrapper {

    .merchant-map-image {
      width: 100%;
      height: 199px;
      object-fit: cover;
      cursor: pointer;
    }
  }
}

// Responsive styles
@media (max-width: 1000px) {
  .merchant-information-container {
    width: 100%;
    padding-top: 2rem;
  }
}

@media (max-width: 768px) {
  .merchant-information-container {
    flex-direction: column;
    height: auto;
    gap: 40px;

    .merchant-information-left,
    .merchant-map-wrapper {
      width: 100%;
      max-width: none;
      padding: 0;
    }

    .merchant-information-buttons-wrapper {
      .merchant-button {
        width: 100%;
        max-width: none;
      }
    }
  }
}

@media (max-width: 400px) {
  .merchant-information-buttons-wrapper {
    .merchant-button {
      width: 100%;
      min-width: 100%;
    }
  }
}