
.receipt-navigation-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    padding: 1rem 0 0 0;
  }

  .share-receipt-button,
  .download-receipt-button {
    display: flex;
    padding: 9px;
    align-items: center;
    gap: 10px;
    background: #FFF9EF;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background: darken(#FFF9EF, 5%);
    }

    .action-icon {
      width: 16px;
      height: 16px;
    }

    span {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      display: flex;
      align-items: center;
      text-align: right;
      color: #1C1C1C;
      white-space: nowrap;
    }
  }

  .share-receipt-button {
    max-width: 150px;
  }

  .download-receipt-button {
    max-width: 150px;
  }
}

// Responsive styles
@media (max-width: 1000px) {
  .receipt-navigation-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .receipt-navigation-container {
    flex-wrap: wrap;
    height: auto;
    gap: 10px;

    .share-receipt-button,
    .download-receipt-button {
      flex: 1;
      justify-content: center;
      width: 100%;
      min-width: 150px;
    }
  }
}

@media (max-width: 400px) {
  .receipt-navigation-container {
    flex-wrap: wrap;
    height: auto;
    gap: 10px;

    .share-receipt-button,
    .download-receipt-button {
      flex: 1;
      justify-content: center;
      min-width: 100%;
    }
  }
}