@import './_variables.scss';

// Custom receipt header styles
.receipt-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  height: 100%; // Add this to ensure full height

  @media (max-width: 768px) {
    display: none;
  }
}

.receipt-header-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  background: $light-yellow;
  border-radius: 10px;
  padding: 0 20px 0 0;
  display: flex;
  align-items: center !important; // Changed from center to stretch
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto; // Reset height for mobile
    padding: 0rem 1rem;
  }
}

.receipt-header-left {
  position: relative;
  width: 100%;
  max-width: 250px;
  height: 100%; // This will now work with parent's fixed height
  background: $medium-yellow;
  border-radius: 10px 80px 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  align-self: stretch; // Added to ensure full height

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
    border-radius: 10px 10px 0 0;
    display: none;
  }
}

.merchant-info-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  .header-text {
    width: 100%;
    font-family: 'Gabarito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    color: $primary-black;
    margin: 0 0 0.5rem 0;
  }

  .merchant-slogan {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    color: $primary-black;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 768px) {
    align-items: flex-start;

    .header-text,
    .merchant-slogan {
      justify-content: flex-start;
      text-align: left;
    }
  }
}

.actions-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
}